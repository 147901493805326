import { Bling as GPT } from "react-gpt";
import AdSense from "./AdSense";
import AdSenseMultiplex from "./AdSenseMultiplex";
import * as tdtag from "./tdtag";
import Script from 'next/script';
import { useRouter } from 'next/router';


const GioneeDetails = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_300x250",
};

const HuaweiHome = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Home_Banner_HW_300x250",
};
const HuaweiDetails = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_HW_300x250",
};
const BharatBrowserHome = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Home_Banner_BB_300x250",
};
const BharatBrowserDetails = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_BB_300x250",
};
const MicromaxHome = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Home_Banner_MMX_300x250",
};
const MicromaxDetails = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_MMX_300x250",
};

const OppoDetailsLS = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_OPLS_300x250",
};

const OppoHomeLS = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Home_Banner_OPLS_300x250",
};

const OppoDetailsBW = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Detail_Banner_OPBW_300x250",
};

const OppoHomeBW = {
  id: "gpt-passback",
  slotSize: [300, 250],
  adUnitPath: "/157165500,22545431593/SPM_ThisDay/SPM_ThisDay_Home_Banner_OPBW_300x250",
};

const XiaomiChronicleHomeMomagic = {
  id: "div-gpt-ad-1772923872952-0",
  slotSize: [[320, 250], [300, 250], [336, 280], [200, 200], [250, 250], 'fluid'],
  adUnitPath: "/22081762831,22545431593/Thisday_tag_1",
}

const XiaomiChronicleHome2Momagic = {
  id: "div-gpt-ad-1682923964376-0",
  slotSize: [[320, 250], [300, 250], [336, 280], [200, 200], [250, 250], 'fluid'],
  adUnitPath: "/22081762831,22545431593/Thisday_tag_2",
}

const XiaomiChronicleDetail1Momagic = {
  id: "div-gpt-ad-1675259225877-0",
  slotSize: [[320, 250], [300, 250], [336, 280], [200, 200], [250, 250], 'fluid'],
  adUnitPath: "/22081762831,22545431593/Thisday_tag1_article",
}

const XiaomiChronicleDetail2Momagic = {
  id: "div-gpt-ad-1675559555519-0",
  slotSize: [[320, 250], [300, 250], [336, 280], [200, 200], [250, 250], 'fluid'],
  adUnitPath: "/22081762831,22545431593/Thisday_tag2_article",
}


const MobitechGAMHome = {
  id: "div-gpt-ad-1680668235268-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Home_GAM",
}

const MobitechGAMDetail1 = {
  id: "div-gpt-ad-1680668151087-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_GAM",
}

const MobitechGAMDetail2 = {
  id: "div-gpt-ad-1680888529273-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_2_GAM",
}

const MobitechGAMDetail3 = {
  id: "div-gpt-ad-1680888949553-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_3_GAM",
}

const HuaweiGAMDetail1 = {
  id: "div-gpt-ad-1681065489909-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_1_HW_GAM",
}

const HuaweiGAMDetail2 = {
  id: "div-gpt-ad-1681065609367-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_2_HW_GAM",
}

const HuaweiGAMDetail3 = {
  id: "div-gpt-ad-1681065722005-0",
  slotSize: [[300, 250]],
  adUnitPath: "/22545431593/ThisDay_Detail_3_HW_GAM",
}


const TestAd = {
  id: "banner-ad",
  slotSize: [300, 250],
  adUnitPath: "/6355419/Travel/Europe/France/Paris",
};
function AdControllerHome(utmSource) {
  switch (utmSource) {
    case "gionee":
      return GioneeDetails;
    case "mmx":
      return MicromaxHome;
    case "bb":
      return BharatBrowserHome;
    case "oppo":
      return OppoHomeLS;
    case "test":
      return TestAd;
    case "oppobrowser":
      return OppoHomeBW;
    case "realmebrowser":
      return MicromaxHome;
    default:
      return {};
    // default: return TestAd;
  }
}
function AdControllerDetails(utmSource) {
  switch (utmSource) {
    case "gionee":
      return GioneeDetails;
    case "mmx":
      return MicromaxDetails;
    case "bb":
      return BharatBrowserDetails;
    case "oppo":
      return OppoDetailsLS;
    case "oppobrowser":
      return OppoDetailsBW;
    case "realmebrowser":
      return MicromaxDetails;
    case "test":
      return TestAd;
    default:
      return {};
    // default: return TestAd;
  }
}
const handleGtagRender = (e) => {
  tdtag.trackEvent({
    event_name: "ad_request_done",
    data_json: {
      ad_path: e.slot.getSlotId().getAdUnitPath(),
      ad_fill: !e.isEmpty && e.size ? "success" : "failure",
    },
  });
};
const handleGtagImpression = (e) => {
  tdtag.trackEvent({
    event_name: "ad_impression",
    data_json: {
      ad_path: e.slot.getSlotId().getAdUnitPath(),
    },
  });
};
export function AdHome(utmSource, utmMedium) {
  let ad = {};
  ad = AdControllerHome(utmSource);
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;

    if (utmMedium === 'notif') {
      return (
        <AdSense clientId={'ca-pub-1883810847636355'}
          slotId={'7643793906'}
          style={{ display: 'block', width: width }}
          isResponsive={true} />
      );
    }
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'6067650796'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'xiaomi') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'6266777213'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'5956246447'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'airtel') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9715616372'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;

    if (utmMedium === 'notif') {
      return (
        <AdSense clientId={'ca-pub-1883810847636355'}
          slotId={'2164521922'}
          style={{ display: 'block', width: width }}
          isResponsive={true} />
      );
    }
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'7196574255'}
        style={{ display: 'inline-block', width: '300px', height: '600px' }}
        isResponsive={false} />
    );
  }
  if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'5944558919'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'phoenix') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'3253014954'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }
  return (
    <AdSense clientId={'ca-pub-1883810847636355'}
      slotId={'8113528153'}
      style={{ display: 'block', width: width }}
      isResponsive={true} />
  );

  // if (utmSource === 'realmebrowser') {
  //   return (
  //     <>
  //       <div id="v-thisday-app"></div>
  //       <script
  //         dangerouslySetInnerHTML={{
  //           __html: `
  //             (function(v,d,o,ai){ai=d.createElement('script');ai.defer=true;ai.async=true;ai.src=v.location.protocol+o;d.head.appendChild(ai);})(window, document, '//a.vdo.ai/core/v-thisday-app/vdo.ai.js');
  //             `,
  //         }}
  //       />
  //     </>
  //   );
  // }
  if (Object.keys(ad).length !== 0) {
    return (
      <GPT
        adUnitPath={ad.adUnitPath}
        slotSize={ad.slotSize}
        collapseEmptyDiv={true}
        // id={ad.id}
        style={{
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "16px",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  }
}

export function AdHomeInfinite(utmSource) {
  let ad = {};
  ad = AdControllerHome(utmSource);
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'1662888342'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'xiaomi') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9155732533'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'3359113392'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'airtel') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'1854460037'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'4048544386'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9855883766'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'phoenix') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'1413583005'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }
  return (
    <AdSense clientId={'ca-pub-1883810847636355'}
      slotId={'3049490462'}
      style={{ display: 'block', width: width }}
      isResponsive={true} />
  );

  // if (utmSource === 'realmebrowser') {
  //   return (
  //     <>
  //       <div id="v-thisday-app"></div>
  //       <script
  //         dangerouslySetInnerHTML={{
  //           __html: `
  //             (function(v,d,o,ai){ai=d.createElement('script');ai.defer=true;ai.async=true;ai.src=v.location.protocol+o;d.head.appendChild(ai);})(window, document, '//a.vdo.ai/core/v-thisday-app/vdo.ai.js');
  //             `,
  //         }}
  //       />
  //     </>
  //   );
  // }
  if (Object.keys(ad).length !== 0) {
    return (
      <GPT
        adUnitPath={ad.adUnitPath}
        slotSize={ad.slotSize}
        collapseEmptyDiv={true}
        // id={ad.id}
        style={{
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "16px",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  }
}

export function AdCategoryInFeed(utmSource) {
  let ad = {};
  ad = AdControllerHome(utmSource);
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9777504820'}
        style={{ display: 'block', width: width }}
        layoutKey={'-h7+n-2l-8z+q0'}
        isInFeed={true} />
    );
  }
  if (utmSource === 'xiaomi') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9155732533'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'3359113392'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  if (utmSource === 'airtel') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'1854460037'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'4048544386'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
  if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9855883766'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }
  return (
    <AdSense clientId={'ca-pub-1883810847636355'}
      slotId={'9855883766'}
      style={{ display: 'block', width: width }}
      isResponsive={true} />
  );

  // if (utmSource === 'realmebrowser') {
  //   return (
  //     <>
  //       <div id="v-thisday-app"></div>
  //       <script
  //         dangerouslySetInnerHTML={{
  //           __html: `
  //             (function(v,d,o,ai){ai=d.createElement('script');ai.defer=true;ai.async=true;ai.src=v.location.protocol+o;d.head.appendChild(ai);})(window, document, '//a.vdo.ai/core/v-thisday-app/vdo.ai.js');
  //             `,
  //         }}
  //       />
  //     </>
  //   );
  // }
  if (Object.keys(ad).length !== 0) {
    return (
      <GPT
        adUnitPath={ad.adUnitPath}
        slotSize={ad.slotSize}
        collapseEmptyDiv={true}
        // id={ad.id}
        style={{
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "16px",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  }
}



export function AdHomeChronicle(utmSource) {
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'1828451055'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'2634034757'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'xiaomi') {
    // let width = (window.screen.width > 600) ? 600 : window.screen.width;
    // return (
    //   <AdSense clientId={'ca-pub-1883810847636355'}
    //     slotId={'9261121152'}
    //     style={{ display: 'block', width: width }}
    //     isResponsive={true} />
    // );
    return (
      <GPT
        adUnitPath={XiaomiChronicleHomeMomagic.adUnitPath}
        slotSize={XiaomiChronicleHomeMomagic.slotSize}
        collapseEmptyDiv={true}
        style={{
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  } else if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9261121152'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'8925945473'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }
  return (
    <AdSense clientId={'ca-pub-1883810847636355'}
      slotId={'8925945473'}
      style={{ display: 'block', width: width }}
      isResponsive={true} />
  );
}

export function AdHomeChronicle2(utmSource) {
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'5528366766'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'7579815036'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'xiaomi') {
    // let width = (window.screen.width > 600) ? 600 : window.screen.width;
    // return (
    //   <AdSense clientId={'ca-pub-1883810847636355'}
    //     slotId={'9533418040'}
    //     style={{ display: 'block', width: width }}
    //     isResponsive={true} />
    // );
    return (
      <GPT
        adUnitPath={XiaomiChronicleHome2Momagic.adUnitPath}
        slotSize={XiaomiChronicleHome2Momagic.slotSize}
        collapseEmptyDiv={true}
        style={{
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  } else if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9533418040'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'4986700469'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }
  return (
    <AdSense clientId={'ca-pub-1883810847636355'}
      slotId={'4986700469'}
      style={{ display: 'block', width: width }}
      isResponsive={true} />
  );
}

export function AdDetailsChronicle(utmSource, position) {
  if (utmSource === 'oppobrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'4913531979'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'realmebrowser') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'8455576761'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'xiaomi') {
    // let width = (window.screen.width > 600) ? 600 : window.screen.width;
    // return (
    //   <AdSense clientId={'ca-pub-1883810847636355'}
    //     slotId={'8028764682'}
    //     style={{ display: 'block', width: width }}
    //     isResponsive={true} />
    // );
    switch (position) {
      case 0:
        return (
          <GPT
            adUnitPath={XiaomiChronicleDetail1Momagic.adUnitPath}
            slotSize={XiaomiChronicleDetail1Momagic.slotSize}
            collapseEmptyDiv={true}
            style={{
              display: "flex !important",
              justifyContent: "center",
              alignItems: "center",
            }}
            onSlotRenderEnded={handleGtagRender}
            onImpressionViewable={handleGtagImpression}
          />
        );
      case 1:
        return (
          <GPT
            adUnitPath={XiaomiChronicleDetail2Momagic.adUnitPath}
            slotSize={XiaomiChronicleDetail2Momagic.slotSize}
            collapseEmptyDiv={true}
            style={{
              display: "flex !important",
              justifyContent: "center",
              alignItems: "center",
            }}
            onSlotRenderEnded={handleGtagRender}
            onImpressionViewable={handleGtagImpression}
          />
        );
    }

  } else if (utmSource === 'xiaomicalendar') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'8028764682'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else if (utmSource === 'huawei') {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'8734373780'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } else {
    let width = 390;
    if (typeof window !== "undefined") {
      width = window.screen.width > 600 ? 600 : window.screen.width;
    }
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'8734373780'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  }
}

export function AdDetails(titleslug, utmSource, id, position, utmMedium) {
  let ad = {};
  ad = AdControllerDetails(utmSource);

  let width = 390;

  if (typeof window !== "undefined") {
    width = window.screen.width > 600 ? 600 : window.screen.width;
    try {
      // console.log("Width ==> " + width);
    } catch (error) {

    }
  }


  // const router = useRouter();
  // var value = router.asPath;
  // // console.log("sub id ==>> " + value);
  switch (position) {
    case 0:
      return (
        <AdSense clientId={'ca-pub-1883810847636355'}
          slotId={'2841020171'}
          style={{ display: 'block', width: width }}
          isResponsive={true} />
      );
    case 1:
      return (
        <AdSense clientId={'ca-pub-1883810847636355'}
          slotId={'1527938502'}
          style={{ display: 'block', width: width }}
          isResponsive={true} />
      );
    case 2:
      return (
        <AdSense clientId={'ca-pub-1883810847636355'}
          slotId={'2478058095'}
          style={{ display: 'block', width: width }}
          isResponsive={true} />
      );

    default:
      break;
  }

  // let width = 390;

  // if (typeof window !== "undefined") {
  //   width = window.screen.width > 600 ? 600 : window.screen.width;
  //   try {
  //     // console.log("Width ==> " + width);
  //   } catch (error) {

  //   }
  // }
  // return (
  //   <AdSense clientId={'ca-pub-1883810847636355'}
  //     slotId={'1622170526'}
  //     style={{ display: 'block', width: width }}
  //     isResponsive={true} />
  // );

  // if (utmSource === 'realmebrowser') {
  //   let width = (window.screen.width > 600) ? 600 : window.screen.width;
  //   return (
  //     <AdSense clientId={'ca-pub-1883810847636355'}
  //       slotId={'1926025538'}
  //       style={{ display: 'block', width: width }}
  //       isResponsive={true} />
  //   );
  // }
  // if (utmSource === 'realmebrowser') {
  //   return (
  //     <>
  //       <div id="v-thisday-app"></div>
  //       <script
  //         dangerouslySetInnerHTML={{
  //           __html: `
  //             (function(v,d,o,ai){ai=d.createElement('script');ai.defer=true;ai.async=true;ai.src=v.location.protocol+o;d.head.appendChild(ai);})(window, document, '//a.vdo.ai/core/v-thisday-app/vdo.ai.js');
  //             `,
  //         }}
  //       />
  //     </>
  //   );
  // }

  if (Object.keys(ad).length !== 0) {
    return (
      <GPT
        adUnitPath={ad.adUnitPath}
        slotSize={ad.slotSize}
        collapseEmptyDiv={true}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSlotRenderEnded={handleGtagRender}
        onImpressionViewable={handleGtagImpression}
      />
    );
  }
}

export function AdToday(titleslug, utmSource, id, position) {

  // if (utmSource === 'oppobrowser') {
  // let width = (window.screen.width > 600) ? 600 : window.screen.width;
  // try {
  //   console.log("Hey ==>> " + utmSource);
  // } catch (error) {

  // }

  // if (utmSource === 'oppobrowser') {
  try {
    let width = (window.screen.width > 600) ? 600 : window.screen.width;
    return (
      <AdSense clientId={'ca-pub-1883810847636355'}
        slotId={'9191449024'}
        style={{ display: 'block', width: width }}
        isResponsive={true} />
    );
  } catch (error) {
    console.log("Error in AdToday ==>> " + error);
  }
  // }

  // return (
  //   <AdSense clientId={'ca-pub-1883810847636355'}
  //     slotId={'9191449024'}
  //     style={{ display: 'block' }}
  //     isResponsive={true} />
  // );
  // }
}


export function AdDetailsRealme(titleslug, utmSource, id, position) {
  // console.log('tyring to add Ads :: ' + titleslug + ' position :: ' + position);
  switch (position) {
    case 0:
      return (
        <div id={`${titleslug}-div-ad-gpt-22545431593-1650884940-0`}>
          <Script

            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['${titleslug}-div-ad-gpt-22545431593-1650884940-0']) {
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1650884940-0').style.minHeight = window._affsz['${titleslug}-div-ad-gpt-22545431593-1650884940-0'].minHeight+'px';
                  // console.log("Tryna Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1650884940-0');
                  if(window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1650884940-0']){
                    // console.log("Displaying Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1650884940-0');
                    googletag.display('${titleslug}-div-ad-gpt-22545431593-1650884940-0');
                  }
                  window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1650884940-0'] = false;
                } else {
                  console.log("Code reached here ==>> " + '${titleslug}-div-ad-gpt-22545431593-1650884940-0');
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1650884940-0').style.display = 'none';
                }
              });
      `,
            }}
          />
        </div>)
    case 1:
      return (
        <div id={`${titleslug}-div-ad-gpt-22545431593-1651132811-0`}>
          <Script

            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['${titleslug}-div-ad-gpt-22545431593-1651132811-0']) {
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1651132811-0').style.minHeight = window._affsz['${titleslug}-div-ad-gpt-22545431593-1651132811-0'].minHeight+'px';
                  // console.log("Tryna Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-0');
                  if(window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1651132811-0']){
                    // console.log("Displaying Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-0');
                  googletag.display('${titleslug}-div-ad-gpt-22545431593-1651132811-0');
                }
                window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1651132811-0'] = false;
                } else {
                  console.log("Code reached here ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-0');
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1651132811-0').style.display = 'none';
                }
              });
          `,
            }}
          />
        </div>)
    case 2:
      return (
        <div id={`${titleslug}-div-ad-gpt-22545431593-1651132811-1`}>
          <Script

            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['${titleslug}-div-ad-gpt-22545431593-1651132811-1']) {
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1651132811-1').style.minHeight = window._affsz['${titleslug}-div-ad-gpt-22545431593-1651132811-1'].minHeight+'px';
                  // console.log("Tryna Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-1');
                  if(window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1651132811-1']){
                    // console.log("Displaying Ad ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-1');
                  googletag.display('${titleslug}-div-ad-gpt-22545431593-1651132811-1');
                }
                window._displayedAd['${titleslug}-div-ad-gpt-22545431593-1651132811-1'] = false;
                } else {
                  console.log("Code reached here ==>> " + '${titleslug}-div-ad-gpt-22545431593-1651132811-1');
                  document.getElementById('${titleslug}-div-ad-gpt-22545431593-1651132811-1').style.display = 'none';
                }
              });
            `,
            }}
          />
        </div>)
    default:
      return (
        <div >

        </div>)
  }
}


export function AdCategories(utmSource, id, position) {
  switch (position) {
    case 0:
      return (
        <div id='div-ad-gpt-22545431593-1650884890-0'>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['div-ad-gpt-22545431593-1650884890-0']) {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-0').style.minHeight = window._affsz['div-ad-gpt-22545431593-1650884890-0'].minHeight+'px';
                  googletag.display('div-ad-gpt-22545431593-1650884890-0');
                } else {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-0').style.display = 'none';
                }
              });
      `,
            }}
          />
        </div>)
    case 1:
      return (
        <div id='div-ad-gpt-22545431593-1650884890-1'>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['div-ad-gpt-22545431593-1650884890-1']) {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-1').style.minHeight = window._affsz['div-ad-gpt-22545431593-1650884890-1'].minHeight+'px';
                  googletag.display('div-ad-gpt-22545431593-1650884890-1');
                } else {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-1').style.display = 'none';
                }
              });
          `,
            }}
          />
        </div>)
    case 2:
      return (
        <div id='div-ad-gpt-22545431593-1650884890-2'>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              googletag.cmd.push(function() {
                if(window._affsz['div-ad-gpt-22545431593-1650884890-2']) {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-2').style.minHeight = window._affsz['div-ad-gpt-22545431593-1650884890-2'].minHeight+'px';
                  googletag.display('div-ad-gpt-22545431593-1650884890-2');
                } else {
                  document.getElementById('div-ad-gpt-22545431593-1650884890-2').style.display = 'none';
                }
              });
            `,
            }}
          />
        </div>)
    case 3:
      return (
        <div id='div-ad-gpt-22545431593-1650884890-3'>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                  googletag.cmd.push(function() {
                    if(window._affsz['div-ad-gpt-22545431593-1650884890-3']) {
                      document.getElementById('div-ad-gpt-22545431593-1650884890-3').style.minHeight = window._affsz['div-ad-gpt-22545431593-1650884890-3'].minHeight+'px';
                      googletag.display('div-ad-gpt-22545431593-1650884890-3');
                    } else {
                      document.getElementById('div-ad-gpt-22545431593-1650884890-3').style.display = 'none';
                    }
                  });
                `,
            }}
          />
        </div>)
    case 4:
      return (
        <div id='div-ad-gpt-22545431593-1650884890-4'>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                      googletag.cmd.push(function() {
                        if(window._affsz['div-ad-gpt-22545431593-1650884890-4']) {
                          document.getElementById('div-ad-gpt-22545431593-1650884890-4').style.minHeight = window._affsz['div-ad-gpt-22545431593-1650884890-4'].minHeight+'px';
                          googletag.display('div-ad-gpt-22545431593-1650884890-4');
                        } else {
                          document.getElementById('div-ad-gpt-22545431593-1650884890-4').style.display = 'none';
                        }
                      });
                    `,
            }}
          />
        </div>)
    default:
      return (
        <div >
        </div>)
  }
}

export function TaboolaHome(position) {

  window._taboola_home_page_counter++;
  // console.log('tyring to add Taboola home Ad :: ' + position);
  // var taboola_container_id = 'taboola-below-home-widget-' + window._taboola_home_page_counter;
  var taboola_container_id = 'taboola-below-home-widget-' + position;
  return (

    <div id={`${taboola_container_id}`} style={{ marginTop: '20px' }}>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          if(!window._displayedAdTaboolaHome['${taboola_container_id}']){
  window._taboola = window._taboola || [];
    console.log("Adding taboola " + '${taboola_container_id}');
  _taboola.push({
    mode: 'thumbnails-h',
    container: '${taboola_container_id}',
    placement: 'Below Home Widget - ${taboola_container_id}',
    target_type: 'mix'
  });
  window._displayedAdTaboolaHome['${taboola_container_id}'] = true;
}
  `,
        }}
      />
    </div >)
}


export function TaboolaChronicleHome(position) {

  // window._taboola_chronicle_page_counter++;
  // console.log('tyring to add Taboola home Ad :: ' + position);
  // var taboola_container_id = 'taboola-below-home-widget-' + window._taboola_home_page_counter;
  var taboola_container_id = 'taboola-mid-homepage-thumbnails-oppo';
  return (

    <div id={`${taboola_container_id}`} style={{ marginTop: '20px' }}>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          if(!window._displayedAdTaboolaChronicle['${taboola_container_id}']){
  window._taboola = window._taboola || [];
    console.log("Adding taboola " + '${taboola_container_id}');
  _taboola.push({
    mode: 'thumbnails-1x1-oppo',
    container: '${taboola_container_id}',
    placement: 'Mid Homepage Thumbnails oppo - ${taboola_container_id}',
    target_type: 'mix'
  });
  window._displayedAdTaboolaChronicle['${taboola_container_id}'] = true;
}
  `,
        }}
      />
    </div >)
}

export function TaboolaCategory(position) {

  window._taboola_home_page_counter++;
  // console.log('tyring to add Taboola home Ad :: ' + position);
  // var taboola_container_id = 'taboola-below-home-widget-' + window._taboola_home_page_counter;
  var taboola_container_id = 'taboola-mobile-below-content-thumbnails-' + position;
  return (

    <div id={`${taboola_container_id}`} style={{ marginBottom: '20px' }}>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          if(!window._displayedAdTaboolaCategory['${taboola_container_id}']){
  window._taboola = window._taboola || [];
    // console.log("Adding taboola " + '${taboola_container_id}');
  _taboola.push({
    mode: 'thumbnails-category',
    container: '${taboola_container_id}',
    placement: 'Mobile Below Content Thumbnails - ${taboola_container_id}',
    target_type: 'mix'
  });
  window._displayedAdTaboolaCategory['${taboola_container_id}'] = true;
}
  `,
        }}
      />
    </div >)
}

export function TaboolaDetail(utmSource, url, slug) {
  // console.log('tyring to add Taboola Ad for  :: ' + url);
  // window._taboola_page_counter++;
  // var taboola_container_id = 'taboola-below-article-' + slug + '-' + window._taboola_page_counter;
  var taboola_container_id = 'taboola-below-article-' + slug;

  // if (!taboolAdElement) {
  // console.log('Div doesnt exist ' + taboolAdElement);


  if (utmSource === "xiaomi") {
    return (
      <div id={`${taboola_container_id}`} style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Script

          dangerouslySetInnerHTML={{
            __html: `
              if(!window._displayedAdTaboolaDetail['${taboola_container_id}']){
            window._taboola = window._taboola || [];
            _taboola.push({mode:'thumbnails-c',
            container: '${taboola_container_id}', 
            placement: 'Mid Article Thumbnails - ${slug}', target_type: 'mix'});
            _taboola.push({article:'auto', url:'${url}'});
            window._displayedAdTaboolaDetail['${taboola_container_id}'] = true;
          }
    `,
          }}
        />
      </div >)
  }

  if (utmSource === "xiaomicalendar") {
    return (
      <div id={`${taboola_container_id}`} style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Script

          dangerouslySetInnerHTML={{
            __html: `
              if(!window._displayedAdTaboolaDetail['${taboola_container_id}']){
            window._taboola = window._taboola || [];
            _taboola.push({mode:'thumbnails-c',
            container: '${taboola_container_id}', 
            placement: 'Below Article Thumbnails - ${slug}', target_type: 'mix'});
            _taboola.push({article:'auto', url:'${url}'});
            window._displayedAdTaboolaDetail['${taboola_container_id}'] = true;
          }
    `,
          }}
        />
      </div >)
  }
  // return (
  //   <div id={`${taboola_container_id}`} style={{ marginLeft: '20px', marginRight: '20px' }}>
  //     <Script

  //       dangerouslySetInnerHTML={{
  //         __html: `
  //             if(!window._displayedAdTaboolaDetail['${taboola_container_id}']){
  //           window._taboola = window._taboola || [];
  //           _taboola.push({mode:'thumbnails-a',
  //           container: '${taboola_container_id}', 
  //           placement: 'Below Article Thumbnails - ${slug}', target_type: 'mix'});
  //           _taboola.push({article:'auto', url:'${url}'});
  //           window._displayedAdTaboolaDetail['${taboola_container_id}'] = true;
  // }
  //   `,
  //       }}
  //     />
  //   </div >)

  if (utmSource === "oppobrowser") {
    return (
      <div id={`${taboola_container_id}`} style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Script

          dangerouslySetInnerHTML={{
            __html: `
              if(!window._displayedAdTaboolaDetail['${taboola_container_id}']){
            window._taboola = window._taboola || [];
            _taboola.push({
              mode: 'thumbnails-a',
              container: '${taboola_container_id}',
              placement: 'Below Article Thumbnails - ${slug}',
              target_type: 'mix'
            });
            _taboola.push({article:'auto', url:'${url}'});
            window._displayedAdTaboolaDetail['${taboola_container_id}'] = true;
  }
    `,
          }}
        />
      </div >)
  }

  if (utmSource === "realmebrowser") {
    return (
      <div id={`${taboola_container_id}`} style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Script

          dangerouslySetInnerHTML={{
            __html: `
                if(!window._displayedAdTaboolaDetail['${taboola_container_id}']){
              window._taboola = window._taboola || [];
              _taboola.push({
                mode: 'thumbnails-a',
                container: '${taboola_container_id}',
                placement: 'Below Article Thumbnails - ${slug}',
                target_type: 'mix'
              });
              _taboola.push({article:'auto', url:'${url}'});
              window._displayedAdTaboolaDetail['${taboola_container_id}'] = true;
    }
      `,
          }}
        />
      </div >)
  }

  // } else {
  //   console.log('Div does exist indeed ' + url);
  // }
}


export function AdCategoryMultiPlexOppo(utmSource) {
  let width = (window.screen.width > 600) ? 600 : window.screen.width;
  return (
    <AdSenseMultiplex clientId={'ca-pub-1883810847636355'}
      slotId={'1697187879'}
      style={{ display: 'block', width: width }}
      format={'autorelaxed'} />
  );
  // }


}


