import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Link from "next/link";
import useGetUTM from "../customHooks/useGetUTM";

const FooterContent = styled.div`
  background-color: #fff;
  padding-bottom: 20px;
`;
const ContentWrapper = styled.div`
  background-color: #fff;
  border-radius: 0 0 22px 22px;
  position: relative;
  .content {
    text-align: center;

    p {
      margin: 0;
      font-family: var(--text-font-montserrat);
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .logo {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    img {
      width: 56px;
      height: 35px;
    }
  }
  .divider {
    padding-left: 40px;
    padding-right: 40px;
  }
  .instalogo {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    img {
      width: 110px;
      height: 37px;
    }
  }
`;
const TncContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 50px 0px 50px;
  p {
    margin: 0;
    font-family: var(--text-font-montserrat);
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #292624;
    text-decoration: underline;
    padding-bottom: 0px;
  }
  a {
    text-decoration: none;
    border-bottom: none;
  }
`;

const CopyRightContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 50px 0px 50px;
  p {
    margin: 0;
    font-family: var(--text-font-montserrat);
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: #292624;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    padding: "6px 16px 6px 16px",
    variant: "middle",
  },
}));

const Footer = () => {
  // const classes = useStyles();
  const { urlString } = useGetUTM();

  return (
    <Grid item sm={12} xs={12} lg={12} md={12}>
      <FooterContent>
        <ContentWrapper>
          <Divider></Divider>
          <div style={{ cursor: 'pointer' }} className="logo">
            <Link href="/" shallow>
              <img src="/icons/thisday-gray.svg" alt="" />
            </Link>
          </div>

          <div>
            <style jsx>{`
              p {
                margin-top: 10px;
              }
            `}</style>
            <TncContainer>
              <Link href="https://angel.co/company/ekank-technologies">
                <a>
                  <p>About Us</p>
                </a>
              </Link>
              <Link href="mailto:feedback@ekank.com">
                <a>
                  <p>Contact Us</p>
                </a>
              </Link>
              <Link href="https://angel.co/company/ekank-technologies">
                <a>
                  <p>Careers</p>
                </a>
              </Link>
            </TncContainer>
          </div>

          <TncContainer>
            <a href={`${process.env.ENV_IMAGE_ROOT}/terms-of-service.html`}>
              <p>
                <style jsx>{`
                  p {
                    padding: 0px 0px 0px 0px;
                  }
                `}</style>
                Terms of Service
              </p>
            </a>
            <a href={`${process.env.ENV_IMAGE_ROOT}/privacy-policy.html`}>
              <p>Privacy Policy</p>
            </a>
          </TncContainer>
          <CopyRightContainer>
            <p>
              <style jsx>{`
                p {
                  margin-top: 10px;
                }
              `}</style>
              © 2021 Ekank Technologies Private Limited
            </p>
          </CopyRightContainer>
          <div className="instalogo">
            <a href="https://www.instagram.com/thisday.app/">
              <img src="/icons/instagram.svg" alt="" />
            </a>
          </div>
        </ContentWrapper>
      </FooterContent>
    </Grid>
  );
};

export default Footer;
