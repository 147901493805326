import React, { useEffect, useState } from 'react';
import useGetUTM from '../components/customHooks/useGetUTM';
import { Waypoint } from 'react-waypoint';
import ReactGA from 'react-ga';
// ReactGA.initialize([
//     {
//         trackingId: 'UA-180483140-5',
//         gaOptions: {
//             name: 'oppobrowser'
//         }
//     },
//     {
//         trackingId: 'UA-180483140-7',
//         gaOptions: {
//             name: 'realmebrowser'
//         }
//     },
//     {
//         trackingId: 'UA-180483140-6',
//         gaOptions: {
//             name: 'all'
//         }
//     }

// ]);


const AdSense = ({ clientId, slotId, style, isResponsive, isInFeed, layoutKey }) => {

    const { utmSource } = useGetUTM();
    const [fired, setFired] = useState(false)

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    const handleEnter = () => {

        if (!fired) {
            setFired(true);
            ReactGA.event({
                category: "AdSense ",
                action: "Ad Inserted",
                label: clientId,
                nonInteraction: true

            }, ["opera"])
            if (utmSource === "oppobrowser") {
                console.log("run")
                ReactGA.event({
                    category: "AdSense ",
                    action: "Ad Inserted",
                    label: clientId,
                    nonInteraction: true

                }, ["oppobrowser"])
            } else if (utmSource === "realmebrowser") {

                ReactGA.event({
                    category: "AdSense ",
                    action: "Ad Inserted",
                    label: clientId,
                    nonInteraction: true

                }, ["realmebrowser"])
            }
        }

    }


    return (
        <Waypoint
            onEnter={handleEnter}
        >

            <div className='ad'>
                {isResponsive ?
                    <ins className='adsbygoogle'
                        style={style}
                        data-ad-client={clientId}
                        data-ad-slot={slotId}
                        data-ad-format='auto'
                        data-full-width-responsive='true'
                    />
                    :
                    <ins className='adsbygoogle'
                        style={style}
                        data-ad-client={clientId}
                        data-ad-slot={slotId}
                    />
                }
                {isInFeed ?
                    <ins className='adsbygoogle'
                        style={{ display: 'block' }}
                        data-ad-format="fluid"
                        data-ad-layout-key={layoutKey}
                        data-ad-client={clientId}
                        data-ad-slot={slotId}
                    />
                    :
                    <></>
                }

            </div>
        </Waypoint>
    );
}

export default AdSense