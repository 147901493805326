import React, { useEffect, useState, useCallback } from 'react';

const useGetParams = () => {
    const [params, setParams] = useState({ utm_source: null, utm_medium: null, utm_campaign: null });
    useEffect(() => {
        const params = window.location.search;
        const urlParams = new URLSearchParams(params);
        const utm_source = urlParams.get("utm_source");
        const utm_medium = urlParams.get("utm_medium");
        const utm_campaign = urlParams.get("utm_campaign")
        localStorage.setItem("utm_source", utm_source);
        localStorage.setItem("utm_medium", utm_medium);
        localStorage.setItem("utm_campaign", utm_campaign)
        setParams({ utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign })

    }, [])

    return params;
}
export default useGetParams;